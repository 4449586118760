import styled from "styled-components";
import { Flex } from "@mechatronick/datum-design-system";

const FlexWrapperCenter = styled(Flex.withComponent("section")).attrs({
  align: "center",
  justify: "center",
  textAlign: "center",
})`
  position: relative;
  z-index: 1;
  max-width: 64em;
  margin: auto auto;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
`;

export default FlexWrapperCenter;
