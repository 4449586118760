import { Link } from "gatsby";
import { Button, theme } from "@mechatronick/datum-design-system";
import styled from "styled-components";

const ButtonWithGatsbyLink = styled(Button.withComponent(Link)).attrs({})`
  display: inline-block;
  text-align: center;
  justify-content: center;
  padding: 20px;
  margin: 10px auto;
  background: ${props => theme.colors[props.color]};
  color: ${theme.colors["trueWhite"]};
  border: 4px solid ${props => theme.colors[props.color]};
  box-shadow: ${theme.boxShadows[1]};
  transition: all 0.4s ease;
  &:hover {
    background: ${theme.colors["trueWhite"]};
    color: ${props => theme.colors[props.color]};
    box-shadow: ${theme.boxShadows[2]};
    transition: all 0.4s ease;
  }
`;

ButtonWithGatsbyLink.defaultProps = {
  color: "accent",
};

export default ButtonWithGatsbyLink;
