import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Loadable from "react-loadable";
import Loading from "../components/Loading";
import Header from "../components/404/Header";

const Nav = Loadable({
  loader: () => import("../components/SharedComponents/Nav"),
  loading: Loading,
});
const SubscribeFooter = Loadable({
  loader: () => import("../components/SharedComponents/SubscribeFooter"),
  loading() {
    return null;
  },
});
const Footer = Loadable({
  loader: () => import("../components/SharedComponents/Footer"),
  loading() {
    return null;
  },
});

export default () => {
  return (
    <Layout>
      <SEO
        title="404 | Datum Consulting NZ "
        keywords={[
          `page not found`,
          `404 error`,
          `aws`,
          `azure`,
          `software development`,
          `digital transformation`,
          `software delivery`,
          `enterprise support`,
          `it consulting`,
        ]}
        pathname="404"
      />
      <Nav />
      <Header />
      <SubscribeFooter />
      <Footer />
    </Layout>
  );
};
