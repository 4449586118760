import React from "react";
import Triangle from "../Triangle";
import ButtonWithGatsbyLink from "../ButtonWithGatsbyLink"
import FlexWrapperCenter from "../FlexWrapperCenter"
import {Box, Heading, Text, theme} from "@mechatronick/datum-design-system";
import {graphql, useStaticQuery} from "gatsby";
import StyledDivWithCoverControlledDimensions from "../StyledDivWithCoverControlledDimensions";

const Header = () => {
    const {headerImage} = useStaticQuery(
        graphql`
      query {
        headerImage: file(relativePath: { eq: "backgroundIndex3.png" }) {
          childImageSharp {
            fluid(maxWidth: 1366) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
    );
    const headerImageSrc = headerImage.childImageSharp.fluid.src;
    return (
        <>
            <StyledDivWithCoverControlledDimensions
                smallScreenHeight={[100, 100]}
                mediumScreenHeight={[100, 100]}
                largeScreenHeight={[100, 100]}
                backgroundImageUrl={headerImageSrc}
            >
                <Triangle
                    color="lightAccent"
                    opacity={0.8}
                    height={[40, 60, 80]}
                    width={[80, 70, 60]}
                />
                <Triangle
                    color="primary"
                    opacity={0.8}
                    height={[12, 17, 22]}
                    width={[105, 110, 115]}
                    invertX
                    invertY
                />
                <Triangle
                    color="lightPrimary"
                    opacity={1}
                    height={[10, 15, 20]}
                    width={[100, 100, 100]}
                    invertX
                    invertY
                />
                <FlexWrapperCenter>
                    <Box
                        p={[2, 5, 6]}
                        m={[2, 0, 0]}
                        bg={"trueWhite"}
                        f={[2, 4, 5]}
                        style={
                            {
                                boxShadow: `${theme.boxShadows[1]}`,
                                textAlign: "center"
                            }
                        }
                    >
                        <Heading.h1
                            color={"errorRed"}
                            f={[6,6,8]}
                            bold
                        >
                            404
                        </Heading.h1>
                        <Text
                            color={"silver"}
                            bold
                            f={[4,5,6]}
                        >
                            We couldn't find this page
                        </Text>
                        <br/>
                        <ButtonWithGatsbyLink
                            to="/"
                        >
                            GO BACK TO HOME PAGE
                        </ButtonWithGatsbyLink>
                    </Box>
                </FlexWrapperCenter>
            </StyledDivWithCoverControlledDimensions>
        </>
    )
};

export default Header;