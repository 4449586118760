import styled from "styled-components";
import { theme } from "@mechatronick/datum-design-system";
import PropTypes from "prop-types";

const Triangle = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  z-index: 0;

  opacity: ${props => (props.opacity === undefined ? 0.8 : props.opacity)};
  ${props => {
    const color = theme.colors[props.color] || props.color;
    const border = `${props.height[0]}vh solid ${color};`;
    return props.invertY
      ? `border-bottom: ${border}; bottom: 0;`
      : `border-top: ${border};`;
  }}
  ${props => {
    const border = `${props.width[0]}vw solid transparent;`;
    return props.invertX
      ? `border-left: ${border}; right: 0;`
      : `border-right: ${border};`;
  }}
  ${theme.mediaQueries.md} {
    ${props => {
      const height = props.height[1];
      return props.invertY
        ? `border-bottom-width: ${height}vh;`
        : `border-top-width: ${height}vh;`;
    }}
    ${props => {
      const width = props.width[1];
      return props.invertX
        ? `border-left-width: ${width}vw;`
        : `border-right-width: ${width}vw;`;
    }};
  }
  ${theme.mediaQueries.lg} {
    ${props => {
      const height = props.height[2];
      return props.invertY
        ? `border-bottom-width: ${height}vh;`
        : `border-top-width: ${height}vh;`;
    }}
    ${props => {
      const width = props.width[2];
      return props.invertX
        ? `border-left-width: ${width}vw;`
        : `border-right-width: ${width}vw;`;
    }};
  }
`;

Triangle.propTypes = {
  invertX: PropTypes.bool,
  invertY: PropTypes.bool,
  height: PropTypes.arrayOf(PropTypes.number).isRequired,
  width: PropTypes.arrayOf(PropTypes.number).isRequired,
  opacity: PropTypes.number,
};

export default Triangle;
